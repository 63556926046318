:root {
  --mchat-primary-hue: 99;
  --mchat-primary-saturation: 61%;
  --mchat-primary-lightness: 46%;
  --mchat-primary-alpha: 1;
  --mchat-secondary-hue: 221;
  --mchat-secondary-saturation: 28%;
  --mchat-secondary-lightness: 20%;
  --mchat-secondary-alpha: 1;
  --mchat-primary: hsla(var(--mchat-primary-hue), var(--mchat-primary-saturation), var(--mchat-primary-lightness), var(--mchat-primary-alpha));
  --mchat-secondary: hsla(var(--mchat-secondary-hue), var(--mchat-secondary-saturation), var(--mchat-secondary-lightness), var(--mchat-secondary-alpha));
  --mchat-neutral-light-hue: 1;
  --mchat-neutral-light-saturation: 1%;
  --mchat-neutral-light-lightness: 96%;
  --mchat-neutral-light-alpha: 1;
  --mchat-neutral-light: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), var(--mchat-neutral-light-lightness), var(--mchat-neutral-light-alpha));
  --mchat-light-text: #fff;
  --mchat-dark-text: #000;
  --mchat-disabled-state-color: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), 60%, 1);
  --mchat-selected-state-color: #66aa4b;
  --mchat-font: Open Sans, sans-serif;
  --mchat-font-size: 1rem;
  --mchat-radius: 25px;
  --mchat-bubble-header-color: var(--mchat-secondary);
  --mchat-incoming-bubble-color: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), 89%, 1);
  --mchat-incoming-bubble-text-color: var(--mchat-dark-text);
  --mchat-outcoming-bubble-color: var(--mchat-primary);
  --mchat-outcoming-bubble-text-color: var(--mchat-light-text);
  --mchat-header-height: 60px;
  --mchat-header-color: var(--mchat-secondary);
  --mchat-header-text-color: var(--mchat-light-text);
  --mchat-background-color: var(--mchat-neutral-light);
  --mchat-panel-background-hue: var(--mchat-secondary-hue);
  --mchat-panel-background-saturation: var(--mchat-secondary-saturation);
  --mchat-panel-background-lightness: var(--mchat-secondary-lightness);
  --mchat-panel-text-color: var(--mchat-light-text);
  --mchat-panel-button-color: var(--mchat-primary);
  --mchat-button-color: var(--mchat-primary);
  --mchat-button-text-color: var(--mchat-light-text);
  --mchat-feedback-value-color: var(--mchat-primary);
  --mchat-feedback-background-color: hsla(var(--mchat-primary-hue), var(--mchat-primary-saturation), 99%, 1);
  --mchat-footer-height: 3em;
  --mchat-user-input-text-color: var(--mchat-dark-text);
  --mchat-action-button-color: transparent;
  --mchat-action-button-content-hue: var(--mchat-primary-hue);
  --mchat-action-button-content-saturation: var(--mchat-primary-saturation);
  --mchat-action-button-content-lightness: var(--mchat-primary-lightness);
  --mchat-action-button-content-color: hsl(var(--mchat-action-button-content-hue), var(--mchat-action-button-content-saturation), var(--mchat-action-button-content-lightness));
  --mchat-action-button-selected-state-content-color: var(--mchat-selected-state-color);
}

body:has(.b-sidebar.is-active) .mchat-opener {
  display: none;
}

@media screen and (max-width: 800px) {
  body:has(.b-product) .mchat-opener {
    display: none;
  }
}

.mchat-container {
  box-sizing: border-box;
  font-size: var(--mchat-font-size);
  font-family: var(--mchat-font);
  opacity: 1;
  z-index: 101;
  height: 100%;
  line-height: 1em;
  transition: opacity .5s;
  display: flex;
  position: fixed;
  bottom: 32px;
  right: 32px;
}

.mchat-container *, .mchat-container :before, .mchat-container :after {
  box-sizing: border-box;
}

@media screen and (min-width: 700px) {
  .mchat-container {
    max-height: 670px;
    padding-top: 64px;
  }
}

.mchat-container--closed {
  max-height: 132px;
}

@media screen and (max-width: 700px) {
  .mchat-container {
    bottom: 24px;
    right: 24px;
  }

  .mchat-container--hidden {
    visibility: hidden;
  }

  .mchat-container--hidden .mchat-opener {
    opacity: 0;
    z-index: -1;
  }

  .mchat-container:not(.mchat-container--closed) {
    height: 100%;
    width: 100%;
    height: 100svh;
    inset: 0;
  }
}

.mchat-container ::-webkit-scrollbar {
  width: .5em;
}

.mchat-container ::-webkit-scrollbar-thumb {
  background: var(--mchat-primary);
  border-radius: 20px;
}

.mchat-container ::-webkit-scrollbar-thumb:hover {
  background: var(--mchat-primary);
}

.mchat-container a {
  color: var(--mchat-primary);
  text-shadow: 0 0 1px #0000001a;
  font-weight: normal;
}

.mchat {
  width: 100%;
  height: 100%;
}

@media (min-width: 700px) {
  .mchat {
    width: 408px;
  }
}

.mchat-wrapper {
  background: var(--mchat-background-color);
  color: var(--mchat-dark-text);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 7px 29px #64646f66;
}

@media (min-width: 700px) {
  .mchat-wrapper {
    border-radius: var(--mchat-radius);
    min-height: 200px;
  }
}

@media screen and (min-width: 700px) and (min-height: 300px) {
  .mchat:before {
    content: " ";
    background: url("robot.8d7c6df7.png");
    width: 93px;
    height: 241px;
    display: block;
    position: absolute;
    bottom: 30px;
    left: -93px;
  }
}

.mchat-text-with-icon {
  justify-content: center;
  align-items: center;
  gap: .25em;
  display: inline-flex;
}

.mchat-primary-color {
  color: var(--mchat-primary);
}

.mchat-time-left-icon {
  background-color: var(--mchat-primary);
  width: 1.5em;
  height: 1.5em;
  -webkit-mask: url("time-left.172b32ed.svg") center no-repeat;
  mask: url("time-left.172b32ed.svg") center no-repeat;
}

.mchat-header {
  color: var(--mchat-header-text-color);
  flex: 0 0 var(--mchat-header-height);
  background-color: var(--mchat-header-color);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.mchat-header__title {
  padding: 1em;
}

.mchat-body {
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  gap: .5em;
  width: 100%;
  padding: 1em;
  display: flex;
  position: relative;
  overflow-x: visible;
  overflow-y: auto;
}

.mchat-body > * {
  overflow-wrap: anywhere;
  max-width: 100%;
}

.mchat-body a {
  overflow-wrap: anywhere;
}

.mchat-body--avatars .mchat-message:not(.mchat-text-message.mchat-message--in):not(.mchat-typing-message) {
  padding-left: 1.4em;
}

.mchat-flex {
  flex: auto;
  justify-content: space-between;
  gap: 1em;
  display: flex;
}

.mchat-bubble {
  gap: .25em;
  display: flex;
  position: relative;
}

.mchat-bubble--in .mchat-bubble__content {
  color: var(--mchat-incoming-bubble-text-color);
  background-color: var(--mchat-incoming-bubble-color);
  border-radius: 0 var(--mchat-radius) var(--mchat-radius);
  padding: 1em;
  line-height: 1.3rem;
}

.mchat-bubble--out .mchat-bubble__content {
  color: var(--mchat-outcoming-bubble-text-color);
  background-color: var(--mchat-outcoming-bubble-color);
  border-radius: var(--mchat-radius) 0 var(--mchat-radius) var(--mchat-radius);
  padding: 1em;
  line-height: 1.3em;
}

.mchat-bubble__avatar > img {
  width: 2.5em;
  max-width: none;
  margin-top: -.5em;
}

.mchat-bubble__header {
  color: var(--mchat-bubble-header-color);
  padding-bottom: .25em;
}

.mchat-bubble__link {
  color: var(--mchat-primary);
  margin-top: .25em;
  display: block;
}

.mchat-image {
  max-width: 408px;
  margin-top: 8px;
}

.mchat-bubble__title {
  margin-bottom: .5em;
  font-size: 1.25em;
  font-weight: 600;
}

.mchat-footer {
  flex: 0 0 var(--mchat-footer-height);
  align-items: center;
  padding: .25em 1em .35em;
  display: flex;
  position: relative;
}

.mchat-footer:before {
  content: "";
  opacity: .9;
  width: 90%;
  height: 2px;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  overflow-y: visible;
  box-shadow: 0 2px 2px #000000b3;
}

.mchat-footer__user-action-wrapper {
  width: 100%;
  height: 3em;
  display: flex;
}

.mchat-footer__input {
  color: var(--mchat-user-input-text-color);
  background: none;
  border: none;
  width: 100%;
  padding: .5em 0 .5em .5em;
  font-size: 1em;
}

.mchat-footer__input:focus-visible, .mchat-footer__input:visited {
  outline: none;
}

.mchat-footer__input--multiline {
  resize: none;
  border-radius: 0;
  margin: .25em 0;
  padding: 0 0 0 .25em;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3em;
  overflow: hidden;
}

.mchat-footer__input::placeholder {
  color: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), 55%, var(--mchat-neutral-light-alpha));
}

.mchat-processing-state {
  color: var(--mchat-header-color);
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
}

.mchat-action-button {
  cursor: pointer;
  background: var(--mchat-action-button-color);
  border: none;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
  transition: all .3s;
  display: flex;
}

.mchat-action-button * {
  font-size: 1.5em;
}

.mchat-action-button > svg {
  fill: var(--mchat-action-button-content-color);
  width: auto;
  height: 1em;
  transition: all .3s;
  display: block;
  position: relative;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    fill: hsl(var(--mchat-action-button-content-hue), var(--mchat-action-button-content-saturation), 30%);
    opacity: .8;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mchat-action-button > svg:hover {
  fill: var(--mchat-selected-state-color);
}

.mchat-action-button:focus {
  box-shadow: none;
  outline: none;
}

.mchat-action-button--audio-active > svg {
  fill: hsl(var(--mchat-action-button-content-hue), var(--mchat-action-button-content-saturation), 30%);
  transform: scale(.8);
}

.mchat-action-button--audio-pulsing > svg {
  animation: 1s infinite pulse;
}

.mchat-action-button:disabled {
  opacity: .3;
  cursor: default;
}

.mchat-action-button:disabled:hover {
  filter: none;
}

.mchat-action-button:disabled:active {
  background-color: var(--mchat-disabled-state-color);
}

.mchat-action-button.mchat-action-button--input-character-counter:disabled {
  opacity: 1;
}

.mchat-action-button__icon {
  background-color: var(--mchat-action-button-content-color);
  width: 1em;
  height: 1em;
  margin: 0;
  padding: 0;
  line-height: 1em;
  transition: all .3s;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: auto;
  mask-size: auto;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.mchat-action-button__icon:hover {
  background-color: var(--mchat-action-button-selected-state-content-color);
}

.mchat-action-button__icon--send {
  -webkit-mask-image: url("send-icon.f0adb7ab.svg");
  mask-image: url("send-icon.f0adb7ab.svg");
}

.mchat-footer__character-counter {
  color: red;
  padding: .7em 0;
  font-weight: bold;
}

.mchat-message {
  gap: .5em;
  width: 100%;
  display: flex;
}

.mchat-message--out {
  flex-direction: row-reverse;
}

@media screen and (max-width: 700px) {
  .mchat-buttons-message {
    align-items: center;
    padding-left: 0;
  }
}

.mchat-text-message a {
  transition: all .3s;
}

.mchat-text-message a:hover {
  color: var(--mchat-selected-state-color);
}

.mchat-hyperlinks-group-message {
  flex-flow: wrap;
  gap: .3em;
  display: flex;
}

@media (max-width: 700px) {
  .mchat-hyperlinks-group-message {
    margin: 0 auto;
  }
}

.mchat-hyperlinks-group-message > * {
  overflow-wrap: anywhere;
  min-width: 0;
}

.mchat-search-message {
  gap: .5em;
}

.mchat-typing-message {
  width: auto;
}

.mchat-typing-message__svg {
  fill: var(--mchat-incoming-bubble-text-color);
  width: 30px;
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
}

.mchat-typing-message__circle-1 {
  animation: 1s infinite typing;
}

.mchat-typing-message__circle-2 {
  animation: 1s .2s infinite typing;
}

.mchat-typing-message__circle-3 {
  animation: 1s .4s infinite typing;
}

.mchat-debug-message {
  color: #fff;
  font-size: 90%;
}

.mchat-debug-message .mchat-bubble {
  background-color: #333131;
  flex-direction: column;
  display: flex;
}

.mchat-debug-message__title {
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.mchat-debug-message__items {
  word-break: break-all;
  flex-direction: column;
  padding-inline-start: 10px;
  list-style: none;
  display: flex;
}

.mchat-closer {
  border-radius: 0 var(--mchat-radius) 0 0;
  cursor: pointer;
  background: var(--mchat-header-color);
  color: inherit;
  border: none;
  padding: 1em;
}

.mchat-closer__icon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
}

.mchat-opener {
  background-color: var(--mchat-primary);
  cursor: pointer;
  border: 1px solid #5fbb2e;
  border-radius: 100%;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 65px;
  height: 65px;
  margin-bottom: 0;
  margin-right: 0;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  box-shadow: 2px 2px 15px 5px #39393a91;
}

@media screen and (min-width: 1300px) {
  .mchat-opener {
    border: none;
    width: 90px;
    height: 90px;
    margin-bottom: 16px;
    margin-right: 16px;
  }
}

.mchat-opener:hover {
  background-color: var(--mchat-selected-state-color);
}

.mchat-opener__icon {
  background-color: var(--mchat-neutral-light);
  width: 3em;
  height: 3em;
  -webkit-mask: url("chat-icon.bf8abe5f.svg") center no-repeat;
  mask: url("chat-icon.bf8abe5f.svg") center no-repeat;
}

@media screen and (max-width: 1300px) {
  .mchat-opener__icon {
    width: 2.25em;
    height: 2.25em;
  }
}

.mchat-hyperlink {
  color: var(--mchat-incoming-bubble-text-color);
  gap: .5em;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 4px;
  font-style: italic;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.mchat-hyperlink:hover {
  color: hsl(var(--mchat-primary-hue), 100%, 40%);
  transform: scale(1.02);
}

.mchat .mchat-button {
  color: var(--mchat-button-text-color);
  background-color: var(--mchat-button-color);
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: .9em 1em;
  font-size: .75em;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.mchat .mchat-button:hover {
  background-color: var(--mchat-selected-state-color);
}

.mchat .mchat-button--selected {
  background-color: var(--mchat-selected-state-color);
  pointer-events: none;
  color: var(--mchat-outcoming-bubble-text-color);
}

.mchat .mchat-icon-button {
  align-items: center;
  gap: .5em;
  display: flex;
}

.mchat .mchat-option-button:disabled:not(.mchat-button--selected) {
  background-color: var(--mchat-disabled-state-color);
  cursor: not-allowed;
}

.mchat .mchat-hyperlink-button {
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

.mchat-option-buttons {
  flex-direction: column;
  align-items: flex-start;
  gap: .5em;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
}

.mchat-option-buttons > * {
  width: 100%;
}

.mchat-audio {
  height: 50px;
  position: absolute;
  bottom: 3.25em;
  left: 23em;
}

.mchat-audio --steady {
  background-color: #f4d03f;
}

.mchat-audio --go {
  background-color: #28b463;
}

.mchat-user-prompt-panel {
  margin-top: var(--mchat-header-height);
  z-index: 100;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.mchat-user-prompt-panel:before {
  content: "";
  background-color: hsla(var(--mchat-secondary-hue), var(--mchat-secondary-saturation), var(--mchat-secondary-lightness), .4);
  z-index: 101;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  animation: 1s both fadeIn;
  display: flex;
  position: absolute;
  inset: 0;
}

@media screen and (min-width: 700px) {
  .mchat-user-prompt-panel:before {
    border-bottom-left-radius: var(--mchat-radius);
    border-bottom-right-radius: var(--mchat-radius);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mchat-user-prompt-panel__body {
  z-index: 102;
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, hsla(var(--mchat-panel-background-hue), var(--mchat-panel-background-saturation), var(--mchat-panel-background-lightness), 95%) 33.5%, hsl(var(--mchat-panel-background-hue), var(--mchat-panel-background-saturation), var(--mchat-panel-background-lightness)) 100%);
  color: var(--mchat-panel-text-color);
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5em;
  width: 90%;
  padding: 1em;
  animation: 1s both fadeIn;
  display: flex;
  position: relative;
}

.mchat-user-prompt-panel__prompt {
  flex-direction: column;
  gap: .25em;
  display: flex;
}

.mchat-user-prompt-panel__actions {
  gap: 10px;
  margin-top: 1em;
  display: flex;
}

.mchat-user-prompt-panel__content {
  white-space: pre-wrap;
  text-align: left;
  border-top: 1px dotted var(--mchat-primary);
  padding: .5em;
  filter: brightness(.8);
  align-self: self-start;
  width: 100%;
  padding-top: 1em;
}

.mchat-user-prompt-panel-button {
  border: 1px solid var(--mchat-button-color);
  background-color: var(--mchat-panel-button-color);
  color: var(--mchat-dark-text);
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 10px;
  margin-top: 8px;
  padding: .5em 1em;
  font-size: .75em;
  transition: all .3s;
  display: flex;
}

.mchat-user-prompt-panel-button:hover {
  color: var(--mchat-light-text);
}

.mchat-user-prompt-panel-button:focus, .mchat-user-prompt-panel-button:focus-visible, .mchat-user-prompt-panel-button:active {
  border: 1px solid var(--mchat-light-text);
  outline: none;
}

.mchat-user-prompt-panel-button--secondary {
  border: 1px solid var(--mchat-primary);
  color: var(--mchat-primary);
  background-color: #0000;
}

.mchat-feedback-message {
  background-color: var(--mchat-feedback-background-color);
  border-color: var(--mchat-primary);
  border: 1px dashed var(--mchat-primary);
  color: var(--mchat-incoming-bubble-text-color);
  border-radius: var(--mchat-radius);
  flex-direction: column;
  max-height: 120px;
  padding: 1em;
  line-height: 1.4em;
  transition: all .2s;
}

.mchat-feedback-message .mchat-feedback__values {
  margin-top: 10px;
}

.mchat-feedback-message--compact {
  background-color: inherit;
  border: none;
  border-radius: 0;
  width: auto;
  padding: .25em .25em .25em 0;
}

.mchat-feedback-message--compact .mchat-feedback__values {
  border-left: 1px solid var(--mchat-primary);
  margin-top: 0;
  padding-left: .5em;
}

.mchat-feedback-message--compact .mchat-feedback__comment {
  margin-left: 1em;
}

.mchat-feedback-message--compact.mchat-feedback-message--comment {
  width: 100%;
}

.mchat-feedback-message--comment {
  max-height: 1000px;
}

.mchat-feedback__title {
  font-weight: bold;
}

.mchat-feedback__text {
  font-style: italic;
}

.mchat-feedback__values {
  color: var(--mchat-feedback-value-color);
  justify-content: center;
  gap: 3px;
  font-size: 1.5em;
  display: flex;
  position: relative;
}

.mchat-feedback__values--editable {
  cursor: pointer;
}

.mchat-feedback__values--editable:hover .mchat-feedback__value--star {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--star {
  color: var(--mchat-incoming-bubble-text-color);
  transition: color .3s;
}

.mchat-feedback__values--editable .mchat-feedback__value--star:hover {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--star:hover ~ .mchat-feedback__value--star {
  color: var(--mchat-incoming-bubble-text-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--thumb {
  font-size: 1.5em;
  transition: transform .2s;
}

.mchat-feedback__values--editable .mchat-feedback__value--thumb:hover {
  transform: scale(1.2);
}

.mchat-feedback__value--selected {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__value--selected.mchat-feedback__value--numeric-scale > * {
  background-color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--selected {
  color: var(--mchat-selected-state-color);
  animation: .25s jump_feedback;
}

.mchat-feedback__comment {
  flex-direction: column;
  gap: .5em;
  margin-top: 10px;
  display: flex;
  position: relative;
}

.mchat-feedback__comment > * {
  margin: 0 auto;
}

.mchat-feedback__comment:before {
  content: "";
  background: var(--mchat-primary);
  width: 20%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.mchat-feedback__comment-title {
  margin-top: 5px;
  margin-bottom: 5px;
}

@keyframes jump_feedback {
  0% {
    top: 0;
  }

  30% {
    top: -10px;
  }

  60% {
    top: 0;
  }

  80% {
    top: 5px;
  }

  100% {
    top: 0;
  }
}

.mchat-feedback__comment-input {
  border: 2px solid var(--mchat-secondary);
  width: 100%;
  padding: .5em 0 .5em .5em;
}

/*# sourceMappingURL=base.css.map */
