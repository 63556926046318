:root {
    
    /* mChat base color palette */  
  
    --mchat-primary-hue: 99;  
    --mchat-primary-saturation: 61%;  
    --mchat-primary-lightness: 46%;  
    --mchat-primary-alpha: 1;
  
    --mchat-secondary-hue: 221;  
    --mchat-secondary-saturation: 28%;  
    --mchat-secondary-lightness: 20%;  
    --mchat-secondary-alpha: 1;

    --mchat-primary: hsla(var(--mchat-primary-hue), var(--mchat-primary-saturation), var(--mchat-primary-lightness), var(--mchat-primary-alpha));
    --mchat-secondary: hsla(var(--mchat-secondary-hue), var(--mchat-secondary-saturation), var(--mchat-secondary-lightness), var(--mchat-secondary-alpha));
    
    /* mChat neutral color palette for backgrounds and text */

    --mchat-neutral-light-hue: 1;
    --mchat-neutral-light-saturation: 1%; 
    --mchat-neutral-light-lightness: 96%;  
    --mchat-neutral-light-alpha: 1;
    
    --mchat-neutral-light: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), var(--mchat-neutral-light-lightness), var(--mchat-neutral-light-alpha));
  
    
    --mchat-light-text: hsl(0,100%,100%);
    --mchat-dark-text: hsl(0,0%,0%);

    --mchat-disabled-state-color: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), 60%, 1);
    --mchat-selected-state-color: hsla(103, 39%, 48%, 1);
    
    // font
    --mchat-font: Open Sans, sans-serif;
    --mchat-font-size: 1.0rem;
    --mchat-radius: 25px;

  // mChat elements

  --mchat-bubble-header-color: var(--mchat-secondary);

  --mchat-incoming-bubble-color: hsla(var(--mchat-neutral-light-hue), var(--mchat-neutral-light-saturation), 89%, 1);
  --mchat-incoming-bubble-text-color: var(--mchat-dark-text);

  --mchat-outcoming-bubble-color: var(--mchat-primary);
  --mchat-outcoming-bubble-text-color: var(--mchat-light-text);

  --mchat-header-height: 60px;
  --mchat-header-color: var(--mchat-secondary);
  --mchat-header-text-color: var(--mchat-light-text);
  
  --mchat-background-color: var(--mchat-neutral-light);

  --mchat-panel-background-hue: var(--mchat-secondary-hue);
  --mchat-panel-background-saturation: var(--mchat-secondary-saturation);
  --mchat-panel-background-lightness: var(--mchat-secondary-lightness);
  --mchat-panel-text-color: var(--mchat-light-text);
  --mchat-panel-button-color: var(--mchat-primary);

  --mchat-button-color: var(--mchat-primary);
  --mchat-button-text-color: var(--mchat-light-text);

  --mchat-feedback-value-color: var(--mchat-primary);
  --mchat-feedback-background-color: hsla(var(--mchat-primary-hue), var(--mchat-primary-saturation), 99%, 1);

  --mchat-footer-height: 3em;
  --mchat-user-input-text-color: var(--mchat-dark-text);

  --mchat-action-button-color: transparent;

  --mchat-action-button-content-hue: var(--mchat-primary-hue);
  --mchat-action-button-content-saturation: var(--mchat-primary-saturation);
  --mchat-action-button-content-lightness: var(--mchat-primary-lightness);

  --mchat-action-button-content-color: hsl(var(--mchat-action-button-content-hue), var(--mchat-action-button-content-saturation), var(--mchat-action-button-content-lightness));
  --mchat-action-button-selected-state-content-color: var(--mchat-selected-state-color);

  }